export default function BlankPage() {
  return (
    <div>
      <p>Welcome to this awesome blank page! :D</p>
      <a href={'/'} style={{ color: 'black' }}>
        Back to Main Page
      </a>
    </div>
  );
}
