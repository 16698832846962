export const execTeamFAQData = [
  {
    question: 'What does STEAM stand for?',
    answer: 'STEAM stands for Science, Technology, Engineering, Art & Design, Maths'
  },
  {
    question: 'How do I learn more about GIS?',
    answer: 'Follow our instagram @girlsinsteamorg'
  },
  {
    question: 'How do I join the GIS team?',
    answer: 'Recruitment for the executive team happens every year in January. Stay tuned!'
  },
  {
    question: 'Who is eligible to apply to the Campus Ambassador Program?',
    answer:
      'All highschool students in Greater Vancouver and college students across North America are welcome to join the Campus Ambassador Program.'
  }
];

export const eventsFAQData = [
  {
    question: 'How do I stay update about upcoming events?',
    answer: 'Check our website  or instagram for upcoming events and registration details.'
  },
  {
    question: 'Is there a registration process for events?',
    answer: 'Yes, please checkout our instagram page for more information on how to register.'
  },
  {
    question: 'Are your events open to people of all genders?',
    answer:
      'There is no restiction on gender to all of our events. Everyone who has a passon for STEAM is welcome to join!'
  },
  {
    question: 'How can I inquire about sponsorship or partnership opportunities for events?',
    answer: 'Please email ubc.sponsorship@girlsinsteam.org about sponsoring our events!'
  }
];
